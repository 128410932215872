import "./styles.css"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Parallax, Pagination } from 'swiper/modules'
import { MdOutlineHub, MdOutlinePrecisionManufacturing, MdEnergySavingsLeaf, MdImportContacts, MdOutlineWarningAmber, MdOutlineConstruction, MdLightbulbOutline } from "react-icons/md";
import { FaSeedling } from "react-icons/fa";


export default function Services() {
    return (
        <section className="services" id="services">
            <Swiper 
                navigation={true}
                modules={[Parallax, Navigation, Pagination]}
                pagination={{dynamicBullets: true}} 
                parallax={true}
                loop={true}
                speed={2000}
                breakpoints={{
                    400: {
                        pagination: {
                            dynamicBullets: true
                        },
                        navigation: {
                            enabled: false
                        }
                    },
                    1200:  {
                        pagination: {
                            enabled: false
                        },
                        navigation: {
                            enabled: true
                        }
                    }
                }}
            >
                <div className="services-image" 
                data-swiper-parallax="-23%" 
                slot="container-start"
                />
                <SwiperSlide>
                    <div className="services-container">
                        <div className="services-text">
                            <h1>Planejamento Estratégico Consciente</h1>
                            <p>Metodologia que gera resultado, o PEC - "Planejamento Estratégico Consciente", que tem como base os princípios do Capitalismo Consciente, é a espinha dorsal de nossos serviços.</p>
                        </div>
                        <div className="services-icon">
                            <MdOutlineHub style={{height: "100%", width: "100%"}}/>
                        </div>
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                <div className="services-container">
                        <div className="services-text">
                            <h1>Gestão de Ativos</h1>
                            <p>Envolve a análise, planejamento e manutenção de ativos, como equipamentos, propriedades e investimentos, para garantir seu uso otimizado ao longo do tempo. Essa prática ajuda a reduzir custos, minimizar riscos e aumentar a eficiência operacional.</p>
                        </div>
                        <div className="services-icon">
                            <MdOutlinePrecisionManufacturing style={{height: "100%", width: "100%"}}/>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                <div className="services-container">
                        <div className="services-text">
                            <h1>Gestão do Conhecimento</h1>
                            <p>Capacitamos nossos clientes a organizar, compartilhar e utilizar informações valiosas para tomar decisões mais informadas e eficazes, impulsionando a aprendizagem contínua e a vantagem competitiva.</p>
                        </div>
                        <div className="services-icon">
                            <MdImportContacts style={{height: "100%", width: "100%"}}/>
                        </div>
                    </div>
                </SwiperSlide> 

                <SwiperSlide>
                <div className="services-container">
                        <div className="services-text">
                            <h1>Gestão da Inovação</h1>
                            <p>Auxiliamos o processo de gerenciar estrategicamente ideias e recursos para promover a criação, desenvolvimento e implementação de novas soluções, produtos ou processos, visando impulsionar a competitividade e o crescimento da organização.</p>
                        </div>
                        <div className="services-icon">
                            <MdLightbulbOutline style={{height: "100%", width: "100%"}}/>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                <div className="services-container">
                        <div className="services-text">
                            <h1>Gestão & Economia de Energia</h1>
                            <p>Implementamos práticas para otimizar o uso de energia, visando reduzir custos, minimizar o impacto ambiental e aumentar a eficiência energética. Isso inclui desde a identificação de oportunidades de economia até a adoção de medidas para monitorar e controlar o consumo.</p>
                        </div>
                        <div className="services-icon">
                            <MdEnergySavingsLeaf style={{height: "100%", width: "100%"}}/>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                <div className="services-container">
                        <div className="services-text">
                            <h1>Gestão de Riscos</h1>
                            <p>Colaboramos com o processo de identificação, avaliação e mitigação de potenciais ameaças ou incertezas que podem afetar os objetivos de uma organização, visando reduzir impactos negativos e aproveitar oportunidades de forma controlada para garantir a continuidade dos negócios.</p>
                        </div>
                        <div className="services-icon">
                            <MdOutlineWarningAmber style={{height: "100%", width: "100%"}}/>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                <div className="services-container">
                        <div className="services-text">
                            <h1>ESG/ABNT PR 2030:2022</h1>
                            <p>Potencializamos sua gestão com consultoria especializada em Sustentabilidade e ESG, por meio da aplicação da ABNT PR 2030:2022. Transformamos Sustentabilidade em vantagem competitiva para sua empresa!</p>
                        </div>
                        <div className="services-icon">
                            <FaSeedling style={{height: "100%", width: "100%"}}/>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                <div className="services-container">
                        <div className="services-li-text">
                            <h1>Outros Serviços</h1>
                            <ul>
                                <li>
                                    Sistemas Integrados de Gestão (Qualidade, Meio Ambiente, Saúde & Segurança Ocupacional e outros);
                                </li>
                                <li>
                                    Ferramentas e metodologias para melhoria de processos;
                                </li>
                                <li>
                                    PNQ/PNQS;
                                </li>
                                <li>
                                    Implantação e auditorias de Sistemas de Gestão (ISO 9001, ISO 14001, ISO 45001, ISO 50001, ISO 55001 e outros).
                                </li>
                            </ul>
                        </div>
                        <div className="services-li-icon">
                            <MdOutlineConstruction style={{height: "100%", width: "100%"}}/>
                        </div>
                    </div>
                </SwiperSlide>



            </Swiper>
        </section>
    )
}