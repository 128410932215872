import "./globalStyle.css"
import 'swiper/css';
import 'swiper/css/bundle'

import Header from "./components/Header"
import FloatButton from "./components/FloatButton";
import WppButton from "./components/WppButton"
import Home from "./sections/Home"
import Footer from "./sections/Footer"
import About from "./sections/About"
import Slider from "./components/Slider"
import Comments from "./sections/Comments";
import Experience from "./sections/Experience";
import Services from "./sections/Services";
import Contact from "./sections/Contact";

export default function App() {
    return (
        <>
            <Header/>
            <FloatButton/>
            <WppButton/>
            <Home/>
            <About/>
            <Slider/>
            <Comments/>
            <Experience/>
            <Services/>
            <Contact/>
            <Footer/>
        </>
    )
}