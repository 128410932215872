import Divider from "../../components/Divider"
import { GoLocation, GoMail, GoDeviceMobile } from "react-icons/go";
import "./styles.css"
import Button from "../../components/Button";

export default function Contact() {
    return (
        <section className="contact" id="contact">
            <div className="contact-container">
                <div className="contact-title-container">
                    <h3>Contato</h3>
                    <Divider/>
                </div>
                <iframe 
                    className="map" 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d58743.39369864526!2d-47.03175447114807!3d-23.043514064461387!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf32972381d79d%3A0x1dd8779027018146!2sVinhedo%2C%20SP%2C%2013280-000!5e0!3m2!1spt-BR!2sbr!4v1708038866424!5m2!1spt-BR!2sbr" 
                    loading="lazy"
                />
                <div className="contact-content">
                    <div className="contact-infos">
                        <div className="contact-info">
                            <div className="contact-icon">
                                <GoLocation size={20}/>
                            </div>
                            <div className="contact-text">
                                <h1>Localização:</h1>
                                <p>Vinhedo (SP)</p>
                            </div>
                        </div>
                        <div className="contact-info">
                            <div className="contact-icon">
                                <GoMail size={20}/>
                            </div>
                            <div className="contact-text">
                                <h1>Email:</h1>
                                <p>edson@edxconsultores.com.br</p>
                            </div>
                        </div>
                        <div className="contact-info">
                            <div className="contact-icon">
                                <GoDeviceMobile size={20}/>
                            </div>
                            <div className="contact-text">
                                <h1>Telefone:</h1>
                                <p>+55 11 99635-2408</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact-form">
                        <form 
                            action="mailto:edson@edxconsultores.com.br" 
                            method="post"
                        >
                            <div className="name-email">
                                <input className="form-control" type="text" name="name" placeholder="Seu Nome"/>
                                <input className="form-control" type="text" name="email" placeholder="Seu e-mail"/>
                            </div>
                            <div className="subject-message">
                                <input className="form-control" type="text" name="subject" placeholder="Assunto"/>
                                <textarea className="form-control" name="message" placeholder="Mensagem"/>
                            </div>
                            <div className="send-form">
                                <button className="primary send-button-responsive" type="submit">
                                    Enviar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}