import "./styles.css"

export default function Home() {

    return (
        <section className="home" id="home">
            <h1>Consultoria Colaborativa
            <span className="blue-dot">.</span>
            </h1>
            <h2>Tiramos do papel a Estratégia da sua Empresa</h2>
        </section>
    )
}