import "./styles.css"
import { IoLogoWhatsapp } from "react-icons/io";

export default function FloatButton () {

    return (
        <a className="wpp-button" href="https://api.whatsapp.com/send?phone=5511996352408&text=Olá! Gostaria de tirar do papel a Estratégia da minha Empresa!">
            <IoLogoWhatsapp size={36}/>
        </a>
    )
}