import "./styles.css"
import { IoMdArrowUp } from "react-icons/io";
import { useEffect, useRef } from "react";

export default function FloatButton () {

    const floatButtonRef = useRef()

    function handleScroll() {
        const position = window.scrollY
        
        if(position === 0) {
            floatButtonRef.current.classList.add("hide-float-button")
        } else {
            floatButtonRef.current.classList.remove("hide-float-button")
        }
    }

    useEffect (() => {
        window.addEventListener("scroll", handleScroll, {passive: true})

        return() => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <a className="float-button" href="#home" ref={floatButtonRef}>
            <IoMdArrowUp size={24}/>
        </a>
    )
}