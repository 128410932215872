import "./styles.css"

export default function About() {
    return (
        <section className="sobre-nos" id="about">
            {/* Texto Sobre nós */}
            <div className="texto-sobre">
                <div>
                    <h1>
                        Uma associação de profissionais altamente qualificados, formamos uma vasta  
                            <span className="rede-de-conhecimento">
                                Rede de Conhecimento
                            </span>
                            .
                    </h1>
                    <p></p>
                    <p>Nosso <span className="proposito">propósito</span> é influenciar a tomada de decisão de nossos clientes, buscando sempre o aumento de sua competitividade.</p>
                    <p>Nossa equipe possui mais de duas décadas e meia de <span className="experiencia">experiência</span> em consultoria em Estratégia & Gestão</p>
                </div>
            </div>
            {/* Imagem */}
            <div className="video-sobre">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/CQKWeiHSshw?si=Q6s736T3I_KobG05&amp;start=2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </section>
    )
}