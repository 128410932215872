import "./styles.css"

export default function Card ({children, image}) {
    return (
        <div className="card-container">
            <div className="card-body">
                <img src={image}/>
            </div>
            <div className="card-footer">
                {children}
            </div>
        </div>
    )
}