import Button from "../Button"
import logo from "../../assets/logo-original.svg"
import "./styles.css"
import { IoIosMenu } from "react-icons/io"
import { useRef, useState } from "react"

export default function Header () {

    const navRef = useRef()

    function toggleModal () {
        navRef.current.classList.toggle("responsive-nav")
    }

    function closeModal () {
        navRef.current.classList.add("responsive-nav")
    }

    return (
        <header>
            <div className="header-container">
                {/* Logo */}
                <div className="logo">
                    <img src={logo}/>
                </div>
                {/* Botões */}
                <div className="nav responsive-nav" ref={navRef}>
                    <a href="#home" onClick={closeModal}>Home</a>
                    <a href="#about" onClick={closeModal}>Sobre Nós</a>
                    <a href="#experience" onClick={closeModal}>Experiência</a>
                    <a href="#services" onClick={closeModal}>Serviços</a>
                    <a href="#contact" onClick={closeModal}>Contato</a>
                </div>
                {/* Contato */}
                <div className="contato">
                    <Button className="outline" href="#about">Vamos Começar!</Button>
                    <a className="modal-toggle" onClick={toggleModal}>
                        <IoIosMenu size={36}/>
                    </a>
                </div>
            </div>
        </header>
    )
}