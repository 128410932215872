import "./styles.css"

import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay, Parallax } from 'swiper/modules'

import client1 from "../../assets/client-1.svg"
import client2 from "../../assets/client-2.svg"
import client3 from "../../assets/client-3.svg"
import client4 from "../../assets/client-4.svg"
import client5 from "../../assets/client-5.svg"
import client6 from "../../assets/client-6.svg"
import client7 from "../../assets/client-7.svg"
import client8 from "../../assets/client-8.svg"
import client9 from "../../assets/client-9.svg"
import client10 from "../../assets/client-10.svg"
import client11 from "../../assets/client-11.svg"
import client12 from "../../assets/client-12.svg"
import client13 from "../../assets/client-13.svg"
import client14 from "../../assets/client-14.svg"
import client15 from "../../assets/client-15.svg"
import client16 from "../../assets/client-16.svg"
import client17 from "../../assets/client-17.svg"
import client18 from "../../assets/client-18.svg"
import client19 from "../../assets/client-19.svg"
import client20 from "../../assets/client-20.svg"
import client21 from "../../assets/client-21.svg"
import client22 from "../../assets/client-22.svg"
import client23 from "../../assets/client-23.svg"
import client24 from "../../assets/client-24.svg"
import client25 from "../../assets/client-25.svg"
import client26 from "../../assets/client-26.svg"
import client27 from "../../assets/client-27.svg"
import client28 from "../../assets/client-28.svg"
import client29 from "../../assets/client-29.svg"

export default function Slider() {
    return(
        <div className="slider-clients">
            <Swiper 
                pagination={{dynamicBullets: true}} 
                modules={[Pagination, Autoplay, Parallax]} 
                loop={true} 
                autoplay={true} 
                speed={1800}
                parallax={true}
                breakpoints={{
                    300: {
                        slidesPerView: 3
                    },
                    400: {
                        slidesPerView: 3
                    },
                    1000:  {
                        slidesPerView: 5
                    }
                }}
            >
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client1}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client29}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client2}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client3}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client4}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client5}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client6}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client7}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client8}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client9}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client28}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client10}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client11}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client12}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client13}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client14}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client15}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client16}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client17}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client18}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client19}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client20}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client21}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client22}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client23}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client24}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client25}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client26}/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slider-images">
                        <img src={client27}/>
                    </div>
                </SwiperSlide>

            </Swiper>
        </div>
    )
}
