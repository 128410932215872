import "./styles.css"
import Card from "../../components/Card"
import Divider from "../../components/Divider"

import image1 from "../../assets/imagem-experience-1.webp"
import image2 from "../../assets/imagem-experience-2.webp"
import image3 from "../../assets/imagem-experience-3.webp"
import image4 from "../../assets/imagem-experience-4.webp"
import image5 from "../../assets/imagem-experience-5.webp"
import image6 from "../../assets/imagem-experience-6.webp"
import image7 from "../../assets/imagem-experience-7.webp"
import image8 from "../../assets/imagem-experience-8.webp"

export default function Experience () {
    return (
        <section className="experience" id="experience">
            <div className="experience-container">
                <div className="experience-title-container">
                    <h3>Experiência</h3>
                    <Divider/>
                </div>
                <div className="experience-cards-container">
                    <Card image={image1}>
                        <h3>Agronegócio & Alimentos</h3>
                    </Card>
                    <Card image={image7}>
                        <h3>Educação</h3>
                    </Card>
                    <Card image={image2}>
                        <h3>Eletroeletrônico & Automação</h3>
                    </Card>
                    <Card image={image3}>
                        <h3>Metalomecânico</h3>
                    </Card>
                    <Card image={image4}> 
                        <h3>Mineração</h3>
                    </Card>
                    <Card image={image5}>
                        <h3>Saneamento Básico</h3>
                    </Card>
                    <Card image={image6}>
                        <h3>Saúde</h3>
                    </Card>
                    <Card image={image8}>
                        <h3>Serviços</h3>
                    </Card>
                </div>
            </div>
        </section>
    )
}