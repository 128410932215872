import "./styles.css"
import Button from "../../components/Button"
import {IoLogoLinkedin, IoLogoWhatsapp, IoLogoInstagram, IoLogoYoutube } from "react-icons/io";

export default function Footer () {
    return (
        <section className="footer">
            <div className="footer-container">
                <div className="footer-top">
                    <div className="footer-text">
                        <h1>Consultoria Colaborativa</h1>
                        <p>Tiramos do papel a Estratégia da sua empresa!</p>
                    </div>
                    <div className="footer-info">
                        <Button className="outline icon-button" href="https://www.instagram.com/edxconsultores/">
                            <IoLogoInstagram size={28}/>
                        </Button>
                        <Button className="outline icon-button" href="https://www.linkedin.com/company/edx-consultores/posts/?feedView=all">
                            <IoLogoLinkedin size={28}/>
                        </Button>
                        <Button className="outline icon-button" href="https://api.whatsapp.com/send?phone=5511996352408&text=Olá! Gostaria de tirar do papel a Estratégia da minha Empresa!">
                            <IoLogoWhatsapp size={28}/>
                        </Button>
                        <Button className="outline icon-button" href="https://www.youtube.com/@EDXConsultores">
                            <IoLogoYoutube size={28}/>
                        </Button>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>Desenvolvido por <span className="rayed-technology">Rayed Technology</span></p>
                    <p>© 2024 EDX Consultores</p>
                </div>
            </div>
        </section>
    )
}